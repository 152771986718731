import React from 'react'
import Page from '../components/Page'

const ImprintPage = () => (
  <Page title="IMPRINT">
    <p className="mt-8 w-2/3 text-center">
    <h1>Imprint</h1>
    Responsible for the content of this website in accordance with Art. 3 para. 1 lit. f of the Federal Act against Unfair Competition (UWG):
<br/>
  </p>
  <p>
Michael Schmid
Obermattweg 12
6052 Hergiswil
Switzerland
</p>
<p>
Contact:
contact AT ip-pal DOT com
</p>
<p>
VAT Identification Number:
CHE-242.685.640
<br/>
Authorized representative:
Michael Schmid
<br/>
Disclaimer:<br/>
The operators of this website assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content.
<br/>
Copyright:<br/>
The content and works on this website are subject to Swiss copyright law. Reproduction, editing, distribution, and any kind of use beyond the limits of copyright law require the written consent of the author or creator. Downloads and copies of this site are permitted for private, non-commercial use only.
<br/>
Data Protection:<br/>
The use of this website is generally possible without providing personal data. If personal data (such as name, address, or email address) is collected on this website, it is always done on a voluntary basis. This data will not be disclosed to third parties without your explicit consent.
<br/>
Please note that this is just a general example, and it may require adjustments based on specific requirements and regulations in Switzerland. It is always advisable to seek legal advice to ensure that the imprint complies with current legal requirements.
  </p>
  </Page>
)

export default ImprintPage
